import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import ReactDomServer from 'react-dom/server';
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import {
  Input, Upload, Modal, Select,
} from 'antd';
import { PlusOutlined, RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import quillTable from 'quill-table';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from 'quill-image-uploader';
import { convertString } from '../../helpers';
import StructuredLink from '../../helpers/LinkChecker';

import './Editor.scss';
import { fetchUploadUrl } from '../../redux/blogSlice';
import { fetchCategories } from '../../redux/categorySlice';

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register(StructuredLink);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register('modules/table', quillTable.TableModule);

const icons = Quill.import('ui/icons');
icons.undo = `${ReactDomServer.renderToString(<UndoOutlined style={{ fontSize: '16px' }} />)}`;
icons.redo = `${ReactDomServer.renderToString(<RedoOutlined style={{ fontSize: '16px' }} />)}`;

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'imageBlot',
  'alt',
  'height',
  'width',
  'style',
  'size',
  'undo',
  'redo',
  'table',
];

const maxRows = 10;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r += 1) {
  for (let c = 1; c <= maxCols; c += 1) {
    tableOptions.push(`newtable_${r}_${c}`);
  }
}

const toolbar = [
  ['bold', 'italic', 'underline'], // toggled buttons
  ['blockquote'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ header: [1, 2, 3, 4, false] }],

  [{
    color: ['black', 'silver', 'gray', 'white', 'maroon', 'red', 'purple', 'fuchsia', 'green', 'lime', 'olive', 'yellow', 'navy', 'blue', 'teal', 'aqua'],
  }],

  ['link'],

  ['image'],

  ['undo'],
  ['redo'],

  [
    { table: tableOptions },
    { table: 'append-row' },
    { table: 'append-col' },
  ],
];

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function Editor({
  text,
  setText,
  fileList,
  setFileList,
  title,
  setTitle,
  type,
  setImageChanged,
  metaDescription,
  setMetaDescription,
  metaTitle,
  setMetaTitle,
  link,
  setLink,
  semanticKeywords,
  setSemanticKeywords,
  mainKeywords,
  setMainKeywords,
  assignee,
  setAssignee,
  language,
  setLanguage,
  isShownInHomepage,
  setIsShownInHomepage,
  publishedAt,
  setPublishedAt,
  setImageUploading,
  categories: blogCategories,
  setCategories,
}) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const quillRef = useRef();

  const { Option } = Select;

  const { user } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.category);
  const { writers, blogs } = useSelector((state) => state.blogs);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [imageNode, setImageNode] = useState();

  const getCategoryPath = (category) => {
    if (!category?.hierarchy) return '';
    const hierarchyText = [
      ...category?.hierarchy?.slice()
        .sort((a, b) => a.category_ranking - b.category_ranking)
        .map((x) => x.name),
    ];
    return `path: ${hierarchyText.join(' > ')} > ${category.name}`;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      const quillObj = quillRef?.current?.getEditor();
      const range = quillObj?.getSelection();

      if (file) {
        if (setImageUploading) setImageUploading(true);

        const response = await fetchUploadUrl(`blog-images/${user?.full_name || 'anonymous'}/${blogs?.length || 0}/${new Date().getTime()}`);
        await fetch(response.data, {
          method: 'PUT',
          body: file,
        });

        const imageUrl = response.data.split('?')[0];

        quillObj.editor.insertEmbed(range.index, 'image', imageUrl);
        quillObj.setContents(quillObj.getContents());

        if (setImageUploading) setImageUploading(false);
      }
    };
  };

  useEffect(() => {
    if (!categories?.length) dispatch(fetchCategories());
  }, [categories]);

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    const editorToolbar = editor?.getModule('toolbar');
    editorToolbar?.addHandler('image', () => {
      imageHandler();
    });
  }, [blogs?.length, user?.full_name]);

  useEffect(() => {
    const ImageBlot = Quill.import('formats/image');
    const Parchment = Quill.import('parchment');

    const quillObj = quillRef?.current?.getEditor();

    quillObj?.root?.addEventListener('click', (event) => {
      const image = Parchment.find(event.target);

      if (image instanceof ImageBlot) {
        setImageNode(image);

        quillObj.setSelection(image.offset(quillObj.scroll), 1, 'user');

        const altImage = image?.domNode?.getAttribute('alt') || '';

        const altEditor = document.getElementsByClassName('alt-editor')[0];
        altEditor.style.left = `${event.clientX}px`;
        altEditor.style.top = `${event.clientY}px`;
        altEditor.style.display = 'block';

        const altInput = document.getElementsByClassName('alt-input')[0];
        altInput.value = altImage;
      } else {
        const altEditor = document.getElementsByClassName('alt-editor')[0];
        altEditor.style.display = 'none';
      }
    });
  }, []);

  const undoHandler = () => {
    const editorObj = quillRef?.current?.getEditor();
    return editorObj.history.undo();
  };

  const redoHandler = () => {
    const editorObj = quillRef?.current?.getEditor();
    return editorObj.history.redo();
  };

  const modules = useMemo(() => ({
    table: true,
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: toolbar,
      handlers: {
        image: imageHandler,
        undo: undoHandler,
        redo: redoHandler,
      },
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
      parchment: Quill.import('parchment'),
    },
    clipboard: {
      matchVisual: false,
    },
  }), []);

  return (
    <>
      <div className="reminders">
        <span className="reminders-title">
          {t('main_layout.reminders')}
        </span>
        <div>
          <span className="reminders-label">{t('editor.main_keywords')}</span>
          <Input.TextArea
            className="reminder-text"
            placeholder={t('editor.main_keywords_placeholder')}
            value={mainKeywords}
            onChange={(e) => {
              setMainKeywords(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  mainKeywords: e.target.value || '',
                }));
              }
            }}
          />
        </div>
        <div>
          <span className="reminders-label">{t('editor.semantic_keywords')}</span>
          <Input.TextArea
            className="reminder-text"
            placeholder={t('editor.semantic_keywords_placeholder')}
            value={semanticKeywords}
            onChange={(e) => {
              setSemanticKeywords(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  semanticKeywords: e.target.value || '',
                }));
              }
            }}
          />
        </div>
      </div>
      <div>
        <div className="writer-and-image">
          { user?.role === 'BloggerAdmin' ? (
            <div className="blog-writer">
              <span className="writer-label">
                {t('editor.blog_writer')}
                :
              </span>
              <Select
                className="writer-field"
                value={assignee}
                onChange={(value) => {
                  setAssignee(value);

                  if (type === 'create') {
                    window.localStorage.setItem('newBlogFields', JSON.stringify({
                      ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                      assignee: value || '',
                    }));
                  }
                }}
                placeholder={t('editor.blog_writer_placeholder')}
                options={
                  writers?.map((writer) => ({ value: writer?._id, label: writer?.full_name }))
                }
              />
            </div>
          )
            : null }
          <div className="blog-main-image">
            <span className="image-label">
              {t('editor.main_image')}
              :
            </span>
            <Upload
              className="upload"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={async ({ fileList: newFileList, file }) => {
                if (type === 'edit') {
                  setImageChanged(true);
                }

                if (fileList.length < newFileList.length) {
                  const thumbUrl = await getBase64(file.originFileObj);
                  setFileList([...newFileList.slice(0, newFileList.length - 1), { ...newFileList[newFileList.length - 1], status: 'done', thumbUrl }]);
                } else if (fileList.length > newFileList.length) {
                  setFileList(newFileList.filter((f) => f.status !== 'removed'));
                }
              }}
            >
              {
                fileList.length >= 1 ? null
                  : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>{t('editor.upload')}</div>
                    </div>
                  )
              }
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={() => setPreviewOpen(false)}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.published_at')}
            :
          </span>
          {/* <DatePicker
            className="field"
            placeholder={t('editor.published_at_placeholder')}
            value={moment(publishedAt)}
            onChange={(e) => {
              setPublishedAt(new Date(e?.$d));
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  publishedAt: new Date(e?.$d) || '',
                }));
              }
            }}
          /> */}
          <DatePicker
            className="field custom-date-picker"
            placeholder={t('editor.published_at_placeholder')}
            selected={publishedAt}
            onChange={(date) => {
              setPublishedAt(date);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  publishedAt: date || '',
                }));
              }
            }}
          />
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.title')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder={t('editor.title_placeholder')}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  title: e.target.value || '',
                }));
              }
            }}
          />
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.link')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder="blog/..."
            value={link}
            onChange={(e) => {
              let newLink = '';

              if (e.target.value.length < 5) {
                newLink = 'blog/';
              } else {
                const withoutBlogsPart = e.target.value?.slice(0, 5) === 'blog/' ? e.target.value.slice(5) : e.target.value;
                newLink = `blog/${convertString(withoutBlogsPart)}`;
              }

              setLink(newLink);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  link: newLink || '',
                }));
              }
            }}
          />
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.meta_title')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder={t('editor.meta_title_placeholder')}
            value={metaTitle}
            onChange={(e) => {
              setMetaTitle(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  metaTitle: e.target.value || '',
                }));
              }
            }}
          />
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.meta_description')}
            :
          </span>
          <Input.TextArea
            className="field"
            placeholder={t('editor.meta_description_placeholder')}
            value={metaDescription}
            onChange={(e) => {
              setMetaDescription(e.target.value);
              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  metaDescription: e.target.value || '',
                }));
              }
            }}
          />
        </div>
        <div className="blog-field">
          <span className="label">
            {t('editor.blog_language')}
            :
          </span>
          <Select
            className="language-field"
            value={language}
            onChange={(value) => {
              setLanguage(value);

              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  language: value || '',
                }));
              }
            }}
          >
            <Option value="tr">{t('languages.Turkish')}</Option>
            <Option value="en">{t('languages.English')}</Option>
          </Select>
        </div>
        <div className="blog-field field-margin">
          <span className="label">
          {t('editor.show_homepage')}
            :
          </span>
          <Select
            className="language-field"
            value={isShownInHomepage === true ? 'true' : 'false'}
            onChange={(value) => {
              const newValue = value === 'true';
              setIsShownInHomepage(newValue);

              if (type === 'create') {
                window.localStorage.setItem('newBlogFields', JSON.stringify({
                  ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                  isShownInHomepage: newValue ? 'true' : 'false',
                }));
              }
            }}
          >
            <Option value="true">{t('editor.show_homepage')}</Option>
            <Option value="false">{t('editor.do_not_show_homepage')}</Option>
          </Select>
        </div>
        { user?.role === 'BloggerAdmin'
          && (
          <div className="blog-field field-margin category-field-container">
            <span className="label">
              {t('editor.categories')}
              :
            </span>

            <Select
              className="category-field"
              showSearch
              onChange={(e) => {
                if(blogCategories?.find(b => b?.toString() === e.split('-')[0]?.toString())) return;
                setCategories([...(blogCategories || []), e.split('-')[0]]);
              }}
            >
              { categories && categories
                .slice()
                .sort((a, b) => a.category_ranking - b.category_ranking)
                .map((category) => (
                  <Option key={category?._id} value={`${category?._id}-${category?.name}`}>
                    {
                      category?.hierarchy[0]?.name
                        && category?.hierarchy?.slice()
                          .sort((a, b) => a.category_ranking - b.category_ranking)[category?.hierarchy.length - 1]?.name
                    }
                    {' '}
                    {category.name}
                    {' '}
                    {category.category_ranking}
                    <div className="hovered-category">{ getCategoryPath(category) }</div>
                  </Option>
                ))}
            </Select>

            <div className="added-categories">
              <h5>Added Categories: </h5>
              {
                blogCategories?.map((c) => (
                  <div className="category-line">
                    <div>{ getCategoryPath(categories?.find((cc) => cc?._id?.toString() === c?.toString())) }</div>
                    <div 
                      className="category-line-remove"
                      onClick={() => setCategories(blogCategories?.filter(b => b?.toString() !== c?.toString()))}
                    >
                      x
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          )}

        <ReactQuill
          theme="snow"
          ref={quillRef}
          modules={modules}
          formats={formats}
          value={text}
          preserveWhitespace
          onChange={((html) => {
            setText(html);
            if (type === 'create') {
              window.localStorage.setItem('newBlogFields', JSON.stringify({
                ...JSON.parse(window.localStorage.getItem('newBlogFields')),
                body: html || '',
              }));
            }
          })}
          placeholder={t('editor.text_placeholder')}
        />
        <div className="alt-editor">
          <div>
            Alt Text:
            {' '}
            <input className="alt-input" type="textarea" />
            <button
              type="button"
              onClick={() => {
                const altInput = document.getElementsByClassName('alt-input')[0];
                imageNode?.domNode?.setAttribute('alt', altInput.value);

                const altEditor = document.getElementsByClassName('alt-editor')[0];
                altEditor.style.display = 'none';
              }}
            >
              {t('buttons.save')}
            </button>
            <button
              type="button"
              onClick={() => {
                const altEditor = document.getElementsByClassName('alt-editor')[0];
                altEditor.style.display = 'none';
              }}
            >
              {t('buttons.cancel')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editor;
