import React from 'react';

import './Blog.scss';

function Blog({ blog }) {

  const handleNavigate = () => {
    window.open(`/blogs/${blog?._id}`, "_blank");
  };

  return (
    <div className="blog-container" onClick={handleNavigate} role="menuitem" onKeyUp={handleNavigate} tabIndex={0}>
      <div className="blog-image">
        <img src={blog?.main_image} alt="blog-main" width={240} height={250} />
      </div>
      <div className="blog-title">{blog?.title}</div>
      <div className="blog-text">
        <p>{blog?.metaDescription}</p>
      </div>
    </div>
  );
}

export default Blog;
